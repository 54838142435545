// React utils
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Images
import Banner from 'assets/images/banners/joinus-footer.gif';

// SCSS
import './Footer.scss';

const Footer = () => {
    const { t } = useTranslation();
    const [actualYear] = useState(new Date().getFullYear());

    return (
        <div className="footer__container">
            {/* <div className="banner__container">
                <a href="https://dades.consellrepublica.cat/register">
                    <img src={Banner} alt="Banner del Consell de la República" />
                </a>
            </div> */}

            <div className="donation__button">
                <Link to={'/donacions'}>
                    <button className="consell__button">Vull fer una donació</button>
                </Link>
            </div>

            <div className="social__icons__container">
                <a href="https://www.facebook.com/consellrep">
                    <FacebookIcon className="social__icon" />
                </a>
                <a href="https://www.twitter.com/consellrep">
                    <TwitterIcon className="social__icon" />
                </a>
                <a href="https://www.instagram.com/consellrep/">
                    <InstagramIcon className="social__icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCMXDoUF3csNT5SEVUsx34SA">
                    <YouTubeIcon className="social__icon" />
                </a>
            </div>
            <div className="faqs__contact__container">
                <Link to={'/FAQs'}>
                    <h2>{t('FAQs')}</h2>
                </Link>
                <Link to={'/contacte'}>
                    <h2>{t('Contacte')}</h2>
                </Link>
            </div>
            <div className="legal__container">
                <Link to={'/avislegal'}>
                    <h2>{t('Avís legal')}</h2>
                </Link>

                <Link to={'/politicadeprivacitat'}>
                    <h2>{t('Política de privacitat')}</h2>
                </Link>

                <Link to={'/politicadegaletes'}>
                    <h2>{t('Política de galetes')}</h2>
                </Link>
            </div>
            <div className="disclaimer__container">
                <h2>Consell de la República</h2>
                <h2>©2018-{actualYear}</h2>
            </div>
        </div>
    );
};

export default Footer;
