// MUI
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({ color = 'black', size = 50 }) => {
    return (
        <div className="loader__container">
            <CircularProgress className="loader__icon" size={size} sx={{ color }} />
        </div>
    );
};

export default Loader;
