import PageModel from 'modules/website/models/PageModel';
import CANDIDATURES from 'modules/website/pages/ElConsell/subpages/Eleccions/constants/constants';
import { useParams } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Button from '@mui/material/Button';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import VideocamIcon from '@mui/icons-material/Videocam';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { useMediaQuery } from 'react-responsive';

function CandidaturaDetail() {
  const { slug } = useParams();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const candidatura = CANDIDATURES.find((c) => c.slug === slug);

  return (
    <PageModel page="candidatura-detail" title="Candidatura a la presidència">
      <div className="flex bg-gray-100 p-4 md:p-12 justify-center rounded-xl">
        <div className="flex flex-col items-center gap-4 max-w-3xl">
          <div className="">
            <div>
              <img
                src={candidatura.photo}
                className="rounded-full w-[250px] h-[250px] object-cover"
                alt=""
              />
            </div>
          </div>
          <h2 className="text-2xl font-bold">{candidatura.name}</h2>
          <div className="flex gap-3">
            {candidatura.socials.twitter && (
              <a
                href={`https://twitter.com/${candidatura.socials.twitter}`}
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon />
              </a>
            )}
            {candidatura.socials.instagram && (
              <a
                href={`https://instagram.com/${candidatura.socials.instagram}`}
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
            )}
          </div>
          {candidatura.biography && (
            <div className="candidatura-biography">
              <h2 className="text-2xl font-bold">Biografia</h2>
              {candidatura.biography}
            </div>
          )}
          {candidatura.objectius && (
            <div className="candidatura-biography">
              <h2 className="text-2xl font-bold">Objectius</h2>
              {candidatura.objectius}
            </div>
          )}
          {candidatura.programa && (
            <div className="candidatura-biography">
              <h2 className="text-2xl font-bold">Programa d'Acció Política</h2>
              {candidatura.programa}
            </div>
          )}

          <div className="flex gap-3 flex-col items-center mb-8">
            {candidatura.videos.length > 0 &&
              candidatura.videos.map((video) => (
                <div className="">
                  <iframe
                    width={isMobile ? '300' : '560'}
                    height={isMobile ? '300' : '315'}
                    src={`https://www.youtube.com/embed/${video}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              ))}
          </div>

          <div className="flex gap-3 flex-col md:flex-row items-center">
            {/* <Button
            variant="outlined"
            startIcon={<HowToVoteIcon />}
            className="hover:text-black hover:border-black transition-all duration-200 ease-in-out"
          >
            Vota al candidat
          </Button> */}
            <a href={candidatura.pregunta} target="_blank" rel="noreferrer">
              <Button
                variant="outlined"
                endIcon={<QuestionAnswerIcon />}
                className="hover:text-black hover:border-black transition-all duration-200 ease-in-out"
              >
                Pregunta al candidat
              </Button>
            </a>
            {candidatura.documentation && (
              <a href={candidatura.documentation} target="_blank" rel="noreferrer">
                <Button
                  variant="outlined"
                  endIcon={<FindInPageIcon />}
                  className="hover:text-black hover:border-black transition-all duration-200 ease-in-out"
                >
                  Documentació
                </Button>
              </a>
            )}
            {candidatura.entrevista && (
              <a href={candidatura.entrevista} target="_blank" rel="noreferrer">
                <Button
                  variant="outlined"
                  endIcon={<LiveHelpIcon />}
                  className="hover:text-black hover:border-black transition-all duration-200 ease-in-out"
                >
                  Entrevista
                </Button>
              </a>
            )}
          </div>
        </div>
      </div>
    </PageModel>
  );
}

export default CandidaturaDetail;
