// React utils
import Loader from 'modules/website/shared/components/Loader';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Components
import App from './App';

// i18n
import './i18n/i18n';

// Scss
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Suspense fallback={<Loader />}>
            <App />
        </Suspense>
    </BrowserRouter>
);
