// React utils
import PropTypes from 'prop-types';
import { useEffect } from 'react';

// Components
import HeaderPages from '../shared/components/HeaderPages';

// SCSS
import './PageModel.scss';

const PageModel = ({ children, page, title, noIndex, hideTitle }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (noIndex) {
      const metaTagNoIndex = document.createElement('meta');
      metaTagNoIndex.name = 'robots';
      metaTagNoIndex.content = 'noindex';

      const metaTagNoFollow = document.createElement('meta');
      metaTagNoFollow.name = 'robots';
      metaTagNoFollow.content = 'nofollow';

      document.head.appendChild(metaTagNoIndex);
      document.head.appendChild(metaTagNoFollow);

      return () => {
        document.head.removeChild(metaTagNoIndex);
        document.head.removeChild(metaTagNoFollow);
      };
    }
  }, [noIndex]);

  return (
    <div className={`${page}__container`}>
      {!hideTitle && (
        <div className="header">
          <HeaderPages title={title} />
        </div>
      )}
      <div className="model__page__content">
        {/* The content */}
        {children}
      </div>
    </div>
  );
};

PageModel.propTypes = {
  page: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  noIndex: PropTypes.bool,
  hideTitle: PropTypes.bool,
};

PageModel.defaultProps = {
  noIndex: false,
  hideTitle: false,
};

export default PageModel;
