// React utils
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import Anchor from './Anchor';
import MenuDropDown from './MenuDropDown';

// Icons
import CancelIcon from '@mui/icons-material/Cancel';

// SCSS
import './MenuTwo.scss';

// const activeClass = 'NavLink-active';
const activeClass = '';

const Menu = ({ toogleMenu }) => {
  const { t } = useTranslation();

  const location = useLocation();

  const [groupActive, setGroupActive] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <div className="menu__container">
      <div className="menu__layer">
        <div className="menu__close__icon" onClick={() => toogleMenu(false)}>
          <CancelIcon className="close__icon" />
        </div>

        <MenuDropDown
          parentLabel={'República Compartida'}
          parentLink={'/republicacompartida'}
          groupActive={groupActive}
          setGroupActive={setGroupActive}
        >
          <NavLink
            to={'/republicacompartida/'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('Visita la pàgina')}</h2>
          </NavLink>
          <NavLink
            to={'/republicacompartida/idrepublicana'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('ID Republicana')}</h2>
          </NavLink>

          <NavLink
            to={'/republicacompartida/pladegovern'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('Pla de Govern')}</h2>
          </NavLink>

          <NavLink
            to={'/republicacompartida/preparemnos'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('Preparem-nos')}</h2>
          </NavLink>

          <NavLink
            to={'/republicacompartida/consultes'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('Consultes')}</h2>
          </NavLink>
        </MenuDropDown>

        <MenuDropDown
          parentLabel={'El Consell'}
          parentLink={'/elconsell'}
          groupActive={groupActive}
          setGroupActive={setGroupActive}
        >
          <NavLink
            to={'/elconsell'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('Què és el Consell?')}</h2>
          </NavLink>

          <Anchor url="/elconsell#reglament" toogleMenu={toogleMenu}>
            {t('Codi General')}
          </Anchor>

          <NavLink
            to={'/elconsell/fites'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('Fites')}</h2>
          </NavLink>

          <NavLink
            to={'/elconsell/publicacions'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('Publicacions')}</h2>
          </NavLink>

          <Anchor url="/elconsell#valors" toogleMenu={toogleMenu}>
            {t('Valors')}
          </Anchor>

          <a href="https://boc.consellrepublica.cat">
            <h2>{t('Butlletí Oficial')}</h2>
          </a>

          <NavLink to={'/elconsell/sindicatura'}>
            <h2>{t('Sindicatura')}</h2>
          </NavLink>

          <Anchor url="/elconsell#members" toogleMenu={toogleMenu}>
            {t('Membres de Govern')}
          </Anchor>
        </MenuDropDown>

        <MenuDropDown
          parentLabel={'Xarxa Diplomatica'}
          parentLink={'/xarxadiplomatica'}
          groupActive={groupActive}
          setGroupActive={setGroupActive}
        >
          <Anchor url="/xarxadiplomatica#objectius" toogleMenu={toogleMenu}>
            {t('Objectius')}
          </Anchor>

          <Anchor url="/xarxadiplomatica#delegats" toogleMenu={toogleMenu}>
            {t('Delegats/des')}
          </Anchor>

          <Anchor url="/xarxadiplomatica#participaxarxa" toogleMenu={toogleMenu}>
            {t('Formulari')}
          </Anchor>
        </MenuDropDown>

        <MenuDropDown
          parentLabel={'Consells Locals'}
          parentLink={'/consellslocals'}
          groupActive={groupActive}
          setGroupActive={setGroupActive}
        >
          <NavLink to={'/consellslocals'}>
            <h2>{t('Preàmbul')}</h2>
          </NavLink>

          <Anchor url="/consellslocals#info" toogleMenu={toogleMenu}>
            {t('Funcions i objectius')}
          </Anchor>

          <Anchor url="/consellslocals#codi" toogleMenu={toogleMenu}>
            {t('Codis')}
          </Anchor>

          {/* <Anchor url="/consellslocals#mapacl" toogleMenu={toogleMenu}>
            {t('Troba el teu')}
          </Anchor> */}

          <Anchor url="/consellslocals#contact" toogleMenu={toogleMenu}>
            {t("Crea'n un")}
          </Anchor>
        </MenuDropDown>

        <MenuDropDown
          parentLabel={'Accions'}
          parentLink={'/accions'}
          groupActive={groupActive}
          setGroupActive={setGroupActive}
        >
          <NavLink
            to={'/accions/'}
            className={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <h2>{t('Visita la pàgina')}</h2>
          </NavLink>

          <a href="https://defensaexili.org">
            <h2>{t('Defensa Exili')}</h2>
          </a>

          <a href="https://www.consuminstitucional.cat/">
            <h2>{t('Consum Institucional')}</h2>
          </a>

          <NavLink to={'/fras'}>
            <h2>{t('Fras')}</h2>
          </NavLink>

          <a href="https://proumonarquia.cat">
            <h2>{t('Proumonarquia')}</h2>
          </a>
        </MenuDropDown>

        <NavLink
          to={'/assembleaterritorial/'}
          className={({ isActive }) => (isActive ? activeClass : undefined)}
        >
          <h2>{t('Assemblea territorial')}</h2>
        </NavLink>

        <div className="menu__group">
          <NavLink to={'/noticies'}>
            <h2>{t('Notícies')}</h2>
          </NavLink>

          <NavLink to={'/esdeveniments'}>
            <h2>{t('Agenda')}</h2>
          </NavLink>

          <NavLink to={'/donacions'}>
            <h2>{t('Donacions')}</h2>
          </NavLink>
        </div>

        <NavLink to={'/elconsell/eleccions-2024'}>
          <h2 className="font-bold">{t('Eleccions a la presidència 2024')}</h2>
        </NavLink>

        {/* <NavLink to={'/elconsell/publicacions'}>
          <h2>{t("Declaració d'independència de la República Catalana")}</h2>
        </NavLink> */}
      </div>
    </div>
  );
};

Menu.propTypes = {
  toogleMenu: PropTypes.func.isRequired,
};

export default Menu;
