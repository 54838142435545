import { HashLink } from 'react-router-hash-link';

const Anchor = ({ url, toogleMenu, children }) => {
    return (
        <HashLink to={url} onClick={() => toogleMenu(false)}>
            <h2>{children}</h2>
        </HashLink>
    );
};

export default Anchor;
