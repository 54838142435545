import { Slide } from 'react-awesome-reveal';
import { NavLink } from 'react-router-dom';

// Icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Scss
import './MenuDropDown.scss';

// const activeClass = 'NavLink-active';
const activeClass = '';

export default function MenuDropDown({
    parentLabel,
    parentLink,
    groupActive,
    setGroupActive,
    children,
}) {
    const name = parentLink.slice(1);
    const actived = groupActive === name;

    const handleShow = () => {
        !actived ? setGroupActive(name) : setGroupActive(null);
    };

    return (
        <div className="menudropdown__container">
            <div className="menudropdown__parent" onClick={handleShow}>
                <div>
                    {actived ? (
                        <KeyboardArrowDownIcon
                            className="menudropdown__parent--icon menudropdown__parent--icon-active"
                            style={{ color: actived ? '#191919' : '' }}
                        />
                    ) : (
                        <KeyboardArrowRightIcon className="menudropdown__parent--icon" />
                    )}
                </div>

                <h2 className="barmenu__link" style={{ color: actived ? '#191919' : '' }}>
                    {parentLabel}
                </h2>
            </div>
            {actived && <div className="menudropdown__children">{children}</div>}
        </div>
    );
}
