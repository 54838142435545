// React utils
import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense, useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';

// Components
import Layout from './modules/website/Layout/Layout';
import Redirect from 'modules/website/shared/components/Redirect';
import Loader from 'modules/website/shared/components/Loader';

// Context
import { AppContextProvider } from 'context/appContext';

// Analytics
import { usePageTracking } from 'modules/website/hooks/usePageTracking';

// SCSS and Styles
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'theme/theme';
import './App.scss';
import CandidaturaDetail from 'modules/website/pages/ElConsell/subpages/Eleccions/components/CandidaturaDetail';

// Pages
const Accions = lazy(() => import('modules/website/pages/Accions/Accions'));
const Agenda = lazy(() => import('modules/website/pages/Agenda/Agenda'));
const AssembleaTerritorial = lazy(() =>
  import('modules/website/pages/AssembleaTerritorial/AssembleaTerritorial')
);
const AvisLegal = lazy(() => import('modules/website/pages/Legal/AvisLegal'));
const ConsellsLocals = lazy(() => import('modules/website/pages/ConsellsLocals/ConsellsLocals'));
const Consultes = lazy(() =>
  import('modules/website/pages/RepCompartida/subpages/Consultes/Consultes')
);
const Contacte = lazy(() => import('modules/website/pages/Contacta/Contacte'));
const Delegacions = lazy(() => import('modules/website/pages/Delegacions/Delegacions'));
const Donacions = lazy(() => import('modules/website/pages/Donacions/Donacions'));
const ElConsell = lazy(() => import('modules/website/pages/ElConsell/ElConsell'));
const FAQs = lazy(() => import('modules/website/pages/FAQs/FAQS'));
const Fites = lazy(() => import('modules/website/pages/ElConsell/subpages/Fites/Fites'));
const Galetes = lazy(() => import('modules/website/pages/Legal/Galetes'));
const GestioDonacions = lazy(() => import('modules/website/pages/GestioDonacions/GestioDonacions'));
const Home = lazy(() => import('modules/website/pages/Home/Home'));
const IdRepublicana = lazy(() => import('modules/website/pages/IdRepublicana/IdRepublicana'));
const Noticies = lazy(() => import('modules/website/pages/Noticies/Noticies'));
const PlaDeGovern = lazy(() =>
  import('modules/website/pages/RepCompartida/subpages/PlaDeGovern/PlaDeGovern')
);
const Preparemnos = lazy(() =>
  import('modules/website/pages/RepCompartida/subpages/Preparemnos/Preparemnos')
);
const Privacitat = lazy(() => import('modules/website/pages/Legal/Privacitat'));
const Publicacions = lazy(() =>
  import('modules/website/pages/ElConsell/subpages/Publicacions/Publicacions')
);
const RepCompartida = lazy(() => import('modules/website/pages/RepCompartida/RepCompartida'));
const Sindicatura = lazy(() =>
  import('modules/website/pages/ElConsell/subpages/Sindicatura/Sindicatura')
);
const SingleEvent = lazy(() => import('modules/website/pages/Agenda/subpage/SingleEvent'));
const SingleNoticia = lazy(() => import('modules/website/pages/Noticies/subpages/SingleNoticia'));
const XxDiplomatica = lazy(() => import('modules/website/pages/XxDiplomatica/XxDiplomatica'));
const FRAS = lazy(() => import('modules/website/pages/FRAS/FRAS'));
const Iframes = lazy(() => import('modules/website/iframes/Iframes'));
const Map = lazy(() => import('modules/website/pages/ConsellsLocals/components/Map/Map'));
const Reactivat = lazy(() => import('modules/website/pages/Landings/Reactivat/Reactivat'));
const Eleccions = lazy(() =>
  import('modules/website/pages/ElConsell/subpages/Eleccions/Eleccions')
);

const cache = createCache({
  key: 'css',
  prepend: true,
});

function App() {
  usePageTracking();

  const [donation, setDonation] = useState({
    mode: 'card',
    name: '',
    email: '',
    type: 'recurrent',
    period: 'mensual',
    quantity: 10,
    processing: false,
    done: false,
    error: false,
    description: 'Consellrepublica.cat',
    iban: '',
    swift: '',
  });

  const handleDonation = (key, value) => {
    setDonation((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <Suspense fallback={<Loader color="black" size={150} />}>
      <CacheProvider value={cache}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppContextProvider value={{ donation, handleDonation }}>
              <Layout>
                <Routes>
                  {/*404*/}
                  <Route path="*" element={<Home />} />
                  {/*  */}
                  {/* Home */}
                  <Route path="/" element={<Home />} />
                  {/*  */}
                  {/* Agenda */}
                  <Route path="/esdeveniments" element={<Agenda />} />
                  <Route path="/esdeveniments/:slug" element={<SingleEvent />} />
                  {/*  */}
                  {/* Republica compartida */}
                  <Route path="/republicacompartida" element={<RepCompartida />} />
                  <Route path="/republicacompartida/idrepublicana" element={<IdRepublicana />} />
                  <Route path="/republicacompartida/pladegovern" element={<PlaDeGovern />} />
                  <Route path="/republicacompartida/preparemnos" element={<Preparemnos />} />
                  <Route path="/republicacompartida/consultes" element={<Consultes />} />
                  {/*  */}
                  {/* El Consell */}
                  <Route path="/elconsell" element={<ElConsell />} />
                  <Route path="/elconsell/eleccions-2024" element={<Eleccions />} />
                  <Route path="/elconsell/eleccions-2024/:slug" element={<CandidaturaDetail />} />
                  <Route path="/elconsell/fites" element={<Fites />} />
                  <Route path="/elconsell/publicacions" element={<Publicacions />} />
                  <Route path="/elconsell/sindicatura" element={<Sindicatura />} />
                  {/*  */}
                  {/* Xarxa diplomatica */}
                  <Route path="/xarxadiplomatica" element={<XxDiplomatica />} />
                  {/*  */}
                  {/* Consells Locals i Delegacions */}
                  <Route path="/consellslocals" element={<ConsellsLocals />} />
                  <Route path="/delegacions" element={<Delegacions />} />
                  {/*  */}
                  {/* Assemblea de representants */}
                  <Route path="/assembleaterritorial" element={<AssembleaTerritorial />} />
                  {/*  */}
                  {/* Accions */}
                  <Route path="/accions" element={<Accions />} />
                  <Route path="/fras" element={<FRAS />} />
                  {/*  */}
                  {/* Noticies */}
                  <Route path="/noticies" element={<Noticies />} />
                  <Route path="/noticies/:slug" element={<SingleNoticia />} />
                  {/*  */}
                  {/* Landing Pages */}
                  <Route path="/reactivat" element={<Reactivat />} />
                  {/*  */}
                  {/* Gestio de donacions */}
                  <Route path="/donacions" element={<Donacions />} />
                  <Route path="/gestiodonacions" element={<GestioDonacions />} />
                  {/*  */}
                  {/* Altres */}{' '}
                  <Route
                    path="/iframes/mapacls"
                    element={
                      <Iframes>
                        <Map />
                      </Iframes>
                    }
                  />
                  <Route path="/contacte" element={<Contacte />} />
                  <Route path="/faqs" element={<FAQs />} />
                  <Route path="/avislegal" element={<AvisLegal />} />
                  <Route path="/politicadeprivacitat" element={<Privacitat />} />
                  <Route path="/politicadegaletes" element={<Galetes />} />
                  {/* Redirects */}
                  <Route
                    path="/wp-content/uploads/2022/10/Verificacions_PPT_v4.pdf"
                    element={
                      <Redirect
                        url={
                          'https://gestio.consellrepublica.cat/wp-content/uploads/2022/10/Verificacions_PPT_v4.pdf'
                        }
                      />
                    }
                  />
                </Routes>
              </Layout>
            </AppContextProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </Suspense>
  );
}

export default App;
