// Components
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { cookieModal } from 'utils/modal';
import Footer from './components/Footer';
import Header from './components/Header';

// SCSS
import './Layout.scss';

const Layout = ({ children }) => {
    const [cookies, setCookie] = useCookies(null);

    const handleCookies = () => {
        setCookie('cookiesConsent', true, { maxAge: 86400 * 30 });
    };

    useEffect(() => {
        if (!cookies.cookiesConsent) {
            cookieModal(handleCookies);
        }
    }, [cookies]);

    return (
        <div className="layout__container">
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
