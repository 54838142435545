import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#fb4b4b',
            main: '#fa1e1e',
            dark: '#af1515',
            contrastText: '#fff',
        },
        secondary: {
            light: '#333333',
            main: '#000000',
            dark: '#000000',
            contrastText: '#000',
        },
    },
});

export default theme;
