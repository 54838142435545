const CANDIDATURES = [
  {
    name: 'Jordi Castellà Andrés',
    slug: 'jordi-castella-andres',
    entrevista:
      'https://gestio.consellrepublica.cat/wp-content/uploads/2024/02/EntrevistaJordiCastella.pdf',
    pregunta: 'https://cxr.kdius.cat/debats/PRP-20240203232010568-YDt+DBT-4020240203234440452-a',
    photo:
      'https://gestio.consellrepublica.cat/wp-content/uploads/2024/02/photo_2024-02-03_16-07-13.jpg',
    videos: ['oFxIM7fGPUw', 'cJTHANXHsE4'],
    biography: (
      <div>
        <p>
          <strong>Advocat, Telemàtic i Regidor a l’Ajuntament de Canet de Mar;</strong> apassionat
          per la història i per la llibertat del nostre país. Especialitzat en història del dret,
          dret públic i internacional, dret contenciós administratiu, dret civil, dret penal i de
          les TIC.
        </p>{' '}
        <p>
          <strong>Autor dels llibres:</strong> “La Via Guineana, per a fer efectiva la independència
          (2023)” i “El Llibre Blanc del dèficit fiscal municipal de Catalunya (2024)."
        </p>{' '}
        <p>
          <strong>Cofundador de:</strong> la Secció local d’Esquerra Republicana de Catalunya de
          Canet de Mar els anys 90; del Partit per la Independència l’any 1996; de l’Associació
          Republicana del Maresme, l’any 1996 i de Primàries Catalunya de Canet de Mar, l’any 2018.
        </p>
      </div>
    ),
    objectius: (
      <div>
        <p>Estimats companys/es, voldria adreçar-vos les següents reflexions:</p>
        <p>
          En primer lloc, crec que el Consell de la República, neix per a fer efectiva la declaració
          d’independència de 2017, no per pactar una acomodació o rendició autonomista de Catalunya.
        </p>
        <p>
          En segon lloc, cal ser conscients, que la declaració d’independència, no va prosperar per
          l’hostilitat i repressió de l’Estat Espanyol (PP-PSOE). Hostilitat que no ha canviat,
          respecte a qualsevol reconeixement del dret a l’autodeterminació de Catalunya. La prova
          Pegasus.
        </p>
        <p>
          Per aquest motiu, davant de la negativa reiterada de l’estat a facilitar el dret a
          l’autodeterminació, l’única solució, legal, pacífica i democràtica possible, serà
          sol·licitar el reconeixement previ del dret a l’autodeterminació i la independència a les
          Nacions Unides, seguint l’exemple d’altres nacions, com Guinea Equatorial, Palestina o el
          Sàhara Occidental, per disposar de l’empara internacional necessària, per a fer-la
          efectiva.
        </p>
      </div>
    ),
    programa: (
      <div>
        <ol>
          <li>
            1. Escollir des del Consell de la República un govern provisional a l’exili, format per
            un president, un primer ministre, un viceministre d’administracions, un ministre
            d’exteriors, un ministre de seguretat i defensa, un ministre d’economia, un ministre de
            justícia, un ministre de cultura, un ministre de medi ambient, un ministre de sanitat,
            un ministeri d’educació, un ministre d’empresa i agricultura, un ministre de drets
            socials i un ministre d’esports.
          </li>
          <li>
            2. Institucionalitzar el govern provisional a l’exili, com a govern de la República
            Catalana provisional a l’exili, emulant l'OLP dels anys 80, presentant-se com govern a
            l’exili davant de les organitzacions internacionals estatals com l'ONU, la UNESCO,
            l’OMS, la UE, la NFTA, l’OEA, el MERCOSUR, la COMUNITAT ANDINA, la CEI, la Unió Àrab del
            Magrib, la UA, l’AEC, la CEDEAO, la SADC, l’AID, la GCC, la SAARC, l’ASEAN i el Fòrum de
            les illes del pacífic.
          </li>
          <li>
            3. Continuar amb el desplegament d’ambaixadors del govern provisional de la República
            Catalana a l’exili, en aquells països més estratègics o amb més presència de la colònia
            catalana a l’estranger, per facilitar relacions i reconeixements.
          </li>
          <li>
            4. Recuperar l’Assemblea de Representants, per encarregar-li l’elaboració d’un procés
            constituent de redacció provisional de la Constitució de la República Catalana, que serà
            sotmès a referèndum per via telemàtica a tots els països catalans.
          </li>
          <li>
            5. Confeccionar el DNI i Passaport de la República Catalana, per a tots els ciutadans de
            la nació catalana que el requereixin, per utilitzar-lo en tots aquells estats o nacions
            sense estat, que el reconeguin internacionalment.
          </li>
          <li>
            6. Defensar judicialment els polítics i activistes catalans amb un fons de solidaritat,
            que serà creat a tal efecte, per facilitar la seva defensa i perseguir les clavegueres
            de l’Estat, ja sigui en l’àmbit estatal com internacional.
          </li>
          <li>
            7. Sol·licitar formalment a l’Estat espanyol, el reconeixement del dret a
            l’autodeterminació i la independència de Catalunya, posant un termini de les actuals
            negociacions bilaterals amb l’Estat, de no més de 6 mesos.
          </li>
          <li>
            8. Sol·licitar el reconeixement internacional, un cop vençut el termini de 6 mesos,
            sol·licitant als estats membres de les Nacions Unides i d’altres nacions sense estat, el
            reconeixement formal del dret a l’autodeterminació i independència de Catalunya.
          </li>
          <li>
            9. Sol·licitar el reconeixement de Territori No Autònom, davant de l’Assemblea General
            de l’ONU, per poder formar part dels Territoris No Autònoms, com Gibraltar, Puerto Rico
            o d’altres, sota la protecció de les Nacions Unides i les seves resolucions
            internacionals, com va aconseguir Guinea Equatorial l’any 1960. Aquest fet no implica
            perdre l’estatut vigent i les competències assolides, al contrari, facilitarà l’augment
            de competències i el traspàs de sobirania, com va passar l’any 1968 a Guinea Equatorial.
          </li>
          <li>
            10. Sol·licitar al C-24 de l’ONU la descolonització i independència de Catalunya, si
            l’Estat espanyol persisteix amb la seva hostilitat i manca de reconeixement, cal
            sol·licitar al Comitè Especial de Descolonització (C-24), l’inici d’un procés de
            descolonització i independència a l’empara de les Nacions Unides, tal com va ser
            possible amb Guinea Equatorial els anys 1959 al 1968.
          </li>
        </ol>
      </div>
    ),
    documentation:
      'https://gestio.consellrepublica.cat/wp-content/uploads/2024/02/Presentació-Candidatura-Jordi-Castellà.pdf',
    socials: {
      twitter: 'jordi_kanet',
    },
  },
  {
    name: 'Carles Puigdemont i Casamajó',
    slug: 'carles-puigdemont-casamajo',
    videos: ['d5fEGi600Mc'],
    entrevista:
      'https://gestio.consellrepublica.cat/wp-content/uploads/2024/02/Entrevista_CarlesPuigdemont.pdf',
    pregunta: 'https://cxr.kdius.cat/debats/PRP-20240203232010568-YDt+DBT-4020240203234856485-D',
    photo:
      'https://gestio.consellrepublica.cat/wp-content/uploads/2024/02/carles_puidemont_fb.jpeg',
    biography: (
      <div>
        <p>Nascut el 29 de desembre de 1962 a Amer (la Selva). </p>

        <p>
          Vaig iniciar estudis de filologia catalana al Col·legi Universitari de Girona, vaig
          desenvolupar la meva carrera periodística especialment a "El Punt". He estat director i
          fundador de l'Agència Catalana de Notícies (ACN) i director general de la publicació
          "Catalonia Today".
        </p>

        <p>
          La meva activitat professional al llarg de gairebé tres dècades ha estat relacionada
          sempre amb la comunicació, excepte el període en què vaig ser director de la Casa de
          Cultura de Girona (2002-2004).{' '}
        </p>

        <p>
          Des del mes de novembre de 2006 em dedico activament a la política institucional:
          <br />
          2006-2016: Regidor de l'Ajuntament de Girona
          <br />
          2011-2016: Alcalde de Girona
          <br />
          2015-2016: President de l'Associació de Municipis de Catalunya per la Independència
        </p>

        <p>
          El gener de 2016 vaig ser investit 130è president de la Generalitat de Catalunya pel
          Parlament de Catalunya
          <br />
          El 2019 escollit diputat al Parlament Europeu
          <br />I des de l'any 2018 presideixo el Govern del Consell de la República.
        </p>
      </div>
    ),
    objectius: (
      <div>
        <p>
          Presento la meva candidatura a presidir el Consell de la República en un context que sé
          que no és fàcil i que té incerteses de les quals es fa difícil de preveure’n la resolució.
          Tanmateix, la nova etapa en què entrarà el Consell de la República no pot desvincular-se
          de les etapes anteriors ni de cap manera del propòsit estratègic recollit al document
          “Preparem-nos”, sinó que ha de significar un avenç per fer-ho possible i incorporar-hi el
          conjunt de l’independentisme que en els darrers anys s’ha allunyat de la unitat
          estratègica.
        </p>

        <p>
          Soc conscient de les dificultats del moviment en general, i dels reptes que té plantejats
          el Consell en particular. En aquest sentit, el Consell ha d’aprofundir més en l’anàlisi de
          la situació per poder formular propostes que ajudin el conjunt del moviment a sortir de la
          paràlisi estratègica i de la divisió interna, i per preparar el país per la culminació del
          procés d’independència iniciat amb el referèndum de l’1 d’octubre de 2017. Tot el que no
          fem nosaltres, no ho farà ningú. Tot el que els catalans deixem de fer, no ens ho farà
          ningú al nostre lloc. Sigui si parlem de la llengua, sigui si parlem de la defensa de les
          nostres institucions.
        </p>

        <p>
          És per això que el Consell va proposar, en acabar el primer mandat, un seguit de propostes
          de millora del seu funcionament que han obtingut l’aval dels registrats, i que ara tocarà
          desenvolupar. Hem de preparar la institució per un nou context polític en què
          l’independentisme ha d’agafar fortalesa i influència, al servei de l’objectiu que
          nosaltres hem mantingut des del dia zero de la nostra fundació: la independència de
          Catalunya i el seu reconeixement internacional. Ja hem vist que les amenaces creixen i
          apunten molt directament el nostre Consell, i això sol ja és un senyal de la necessitat de
          la seva existència. Com hem fet sempre, hi plantarem cara. Alguns de nosaltres som
          perseguits en totes les causes i per infinitat de crims; la majoria afortunadament no ho
          ha de patir i no han de destinar part del seu temps i dels seus esforços a defensar-se
          dels atacs i de les agressions. Tots sabem per quina raó ens passa això. Si ho haguéssim
          deixat estar, si haguéssim restat passius, sense fer res; si haguéssim acceptat dreceres,
          avui no ens trobaríem personalment en aquesta situació. Però les conseqüències polítiques
          haurien estat molt negatives. Gràcies al vostre acompanyament, ho hem resistit.
        </p>

        <p>
          En aquest context, l’autoestima i l’empatia són fonamentals. La comprensió de les nostres
          limitacions i errors no ha d’anul·lar l’autoestima i la fortalesa interior per assolir
          l’objectiu final. Ens cal entrar en un període nou, de superació i reparació, on
          l’independentisme recuperi el lideratge per poder abordar l’embat final. L’eina d’aquesta
          estratègia és el Consell de la República.
        </p>
      </div>
    ),
    programa: (
      <div>
        <ol>
          <li>
            1. Preservar el mandat de l'1-O: Continuar defensant la voluntat expressada pel poble
            català l'1 d'octubre del 2017. Aquesta defensa es manifestarà en totes les accions i
            decisions preses pel Consell.
          </li>
          <li>
            2. Internacionalització - La xarxa diplomàtica: Treballar per ampliar i consolidar la
            Xarxa Diplomàtica del Consell. Això implicarà establir relacions amb altres nacions i
            organismes internacionals per guanyar suport per a la causa catalana.
          </li>
          <li>
            3. Desplegament al territori - Els Consells Locals i Territorials: Treballar per
            aprofundir la presència del Consell de la República a tot el territori català. Això es
            farà mitjançant la creació i el suport als Consells Locals, que actuaran com a extensió
            del Consell de la República en l'àmbit local.
          </li>
          <li>
            4. Eines de desconnexió: Continuar treballant en el desenvolupament d'eines que
            facilitin la desconnexió de l'Estat espanyol. Seguir implementant la Identitat Digital
            Republicana (IDR), la seguretat tecnològica, la promoció de la campanya Consum
            Institucional a través de la signatura de convenis amb ajuntaments i el desplegament del
            full de ruta #PreparemNos.
          </li>
          <li>
            5. Defensa de la llengua i la cultura: Treballar per protegir i promoure la llengua i la
            cultura catalanes. Això implicarà polítiques que fomentin l'ús del català en tots els
            àmbits de la vida pública i privada, així com el suport a les institucions i activitats
            culturals catalanes.
          </li>
        </ol>
      </div>
    ),
    socials: {
      twitter: 'krls',
      instagram: 'carlespuigdemont',
    },
  },
  {
    name: 'Lluís Felipe Lorenzo',
    slug: 'lluis-felipe-lorenzo',
    videos: ['K9jbAgINwpA'],
    pregunta: 'https://cxr.kdius.cat/debats/PRP-20240203232010568-YDt+DBT-4020240203234008241-Y',
    entrevista:
      'https://gestio.consellrepublica.cat/wp-content/uploads/2024/02/Entrevista_LFelipeLorenzo.pdf',
    photo:
      'https://gestio.consellrepublica.cat/wp-content/uploads/2024/02/photo_2024-02-04_13-54-04.jpg',
    biography: (
      <div>
        <p>Nascut el 17/06/73 a Palma, Mallorca, on visc.</p>

        <p>
          Soc llicenciat en Periodisme. Vaig fer pràctiques al Gabinet de Comunicació d’Amnistia
          Internacional a Madrid.
        </p>

        <p>
          Vaig iniciar l’experiència laboral a Diari de Girona. També he publicat al Diari de
          Balears. Puc assegurar que la majoria de la meva creació periodística ha estat en llengua
          catalana.
        </p>

        <p>Tinc un postgrau en Informació Internacional i Països del Sud.</p>

        <p>
          He estat guardonat amb el Premi de la República d’Algèria per solidaritat amb el
          terratrèmol de 2003.
        </p>
        <p>No estic adscrit a cap partit polític.</p>
      </div>
    ),
    programa: (
      <div>
        <ol>
          <li>
            1. Negociar amb Algèria la creació d’un microestat a Bab-el-oued (barri on es parlava
            català fins fa 60 anys) amb l’únic requisit per part del Consell que la llengua catalana
            sigui la única llatina oficial i mantenir la llibertat religiosa.
          </li>
          <li>
            2. La defensa militar correria a càrrec d’Algèria. Una vegada creat el microestat
            insistir en el reconeixement del resultat de l’1-O sense donar marge a cap negociació
            que l’invalidi o que reconeixi que hi va haver delicte objecte d’una possible amnistia.
            Admetre l’amnistia és admetre el delicte.
          </li>
          <li>
            3. El Pressupost vendria determinat pel cost de la negociació amb Algèria. Si s’hi crea
            una ciutat autònoma o microestat s’hi podria generar activitat per recaptar imposts. Que
            sigui fora de la UE i de l’OTAN és un avantatge perquè no dependrà dels socis més
            propers del Regne d’Espanya.
          </li>
          <li>
            4. A llarg termini l’objectiu seria reunificar els Països Catalans i una confederació
            amb Occitània i un tractat de cooperació amb el Principat de Mònaco, on la llengua
            occitana té un reconeixement secundari. Tots aquests objectius sempre basant-nos en
            instruments democràtics.
          </li>
          <li>5. La filosofia és no dependre exclusivament del diàleg amb Madrid o París.</li>
          <li>
            6. El fet de ser periodista redueix la possibilitat de ser jutjat perquè, si ho accepta
            el Consell, la funció és també reunir informació publicable. Un Estat democràtic té
            complicat detenir i jutjar un periodista, sobretot si té passaport portuguès.
          </li>
          <li>
            7. Amb tot, sóc contrari al discurs de l’odi envers França o Espanya i estic a favor
            dels agraïments per haver mantingut una lluita que podem guanyar. Res d’insults i
            violència.
          </li>
          <li>
            8. Vaig assistir a la presentació del llibre “El Sol sortí pel sud”, de Joan Riera Frau,
            on explica els fets de l’1-0. Relacion el títol també amb la Catalunya algeriana
            (Bab-el-oued). Tenim bones experiències amb Algèria, com les relacions amb els
            saharians, molts dels quals parlen català. També el republicanisme."
          </li>
        </ol>
      </div>
    ),
    documentation: '',
    socials: {},
  },
];

export default CANDIDATURES;
