// React utils
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// SCSS
import './HeaderPages.scss';

const HeaderPages = ({ title }) => {
    const { t } = useTranslation();

    return (
        <div className="headerpage__container">
            <h1>
                {title} <br /> <span>{t('del Consell de la República')}</span>
            </h1>
        </div>
    );
};

HeaderPages.propTypes = {
    title: PropTypes.string.isRequired,
};

export default HeaderPages;
