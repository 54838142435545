import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const colors = {
    red: '#fa1e1e',
    black: '#000000',
};

const messages = {
    contactForm: {
        title: 'Formulari enviat correctament',
    },
    cancelSubscription: {
        title: 'Subscripció cancel·lada correctament',
    },
};

export const doneModal = (type) => {
    const { title } = messages[type];

    MySwal.fire({
        title: <strong>{title}</strong>,
        icon: 'success',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        color: colors.black,
    });
};

export const errorModal = (error = 'Alguna cosa ha anat malament') => {
    Swal.fire({
        icon: 'error',
        text: error,
        confirmButtonText: "D'acord",
        confirmButtonColor: '#000000',
        iconColor: colors.red,
    });
};

export const confirmationModal = (actionText, action) => {
    Swal.fire({
        icon: 'warning',
        title: `Vols ${actionText}?`,
        showDenyButton: true,
        confirmButtonText: `Sí, vull ${actionText}`,
        confirmButtonColor: '#212121',
        iconColor: '#fa1e1e',
        color: '#212121',
    }).then((result) => {
        if (result.isConfirmed) {
            action();
        }
    });
};

export const cookieModal = (action) => {
    MySwal.fire({
        html: (
            <p style={{ fontSize: '1rem', margin: 0, padding: 0 }}>
                Recopilem i processem la vostra informació personal amb les següent finalitats:
                Funcionalitat, Analítica i Publicitat.{' '}
            </p>
        ),
        confirmButtonText: `Acceptar`,
        confirmButtonColor: '#212121',
        iconColor: '#fa1e1e',
        color: '#ffffff',
        position: 'bottom-end',
        width: '100%',
        customClass: {
            container: 'cookieconsent__general',
            popup: 'cookieconsent__popup',
            confirmButton: 'cookieconsent__button',
        },
        background: '#fa1e1e',
    }).then((result) => {
        if (result.isConfirmed) {
            action();
        }
    });
};
