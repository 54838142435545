// React utils
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Icons
import LoginIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

// Assets
import Logo from 'assets/images/logo.png';

// Components
import LangSelector from 'modules/website/shared/components/LangSelector';
import MenuTwo from 'modules/website/shared/components/MenuTwo';

// SCSS
import './Header.scss';

const Header = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [menuOpened, setMenuOpened] = useState(false);

  const toogleMenu = (action) => {
    action ? setMenuOpened(true) : setMenuOpened(false);
  };

  useEffect(() => {
    toogleMenu(false);
  }, [location]);

  return (
    <div className="header__container">
      {menuOpened && <MenuTwo toogleMenu={toogleMenu} />}

      <div className="logo__container">
        <Link to={'/'}>
          <img src={Logo} alt="Logo del Consell de la República" />
        </Link>

        {/* <LangSelector /> */}
      </div>
      <div className="elements__container">
        <div className="login__container">
          <a href="https://dades.consellrepublica.cat/login">
            <h2 className="login__text">{t('El teu perfil ciutada')}</h2>
            <LoginIcon className="login__icon" />
          </a>
        </div>
        <div className="joinus__container">
          <a href="https://dades.consellrepublica.cat/register">
            <button>{t('Fes-te del Consell')}</button>
          </a>
        </div>
        <div className="menu__icon__container">
          <MenuIcon className="menu__icon" onClick={() => toogleMenu(true)} />
        </div>
      </div>
    </div>
  );
};

export default Header;
